<template>
    <div class="plate-center">
        <!-- 面包屑 S -->
        <div class="yh-h88 level_align yh-fz14">
            当前位置：
            <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="yh-fc-blue-dan">帮助中心</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!-- 面包屑 E -->

        <!--列表 S-->
        <ul>
            <li
                @click="pageJumps('RichTextDetails',{router:JSON.stringify([{name:'首页',path: '/'},{name:'帮助中心',path: '/heleCenter'}]),pageName: '详情',id: item.id, api: 'newshelpDetail'})"
                class="yh-h88 level_align flex_between yh-ptb0lr20 yh-bbox yh-cp"
                v-for="(item,index) in list"
                :key="index"
            >
                {{item.title}}
                <span>{{item.create_time}}</span>
            </li>
        </ul>
        <!--列表 E-->

        <!-- 分页器 S-->
        <el-pagination
            class="center_combo yh-h88"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="page.per_page"
            layout="prev, pager, next"
            :total="page.total"
        ></el-pagination>
        <!-- 分页器 E-->
    </div>
</template>

<script>
export default {
    name: "helpCenter",
    data() {
        return {
            list: [],
            page: {
                per_page: 10, // 每页数量
                page: 1, // 页码
                total: null, // 总页数
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        // 获取章节详情
        getList() {
            this.$axios.post("/api/index/newshelpList").then((res) => {
                if (res.code === 1) {
                    this.list = res.data.data;
                    this.page.total = res.data.total;
                }
            });
        },
        handleSizeChange() {
            this.getList();
        },
        handleCurrentChange() {
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
ul {
    border-top: 1px dotted #999999;
    li {
        border-bottom: 1px dotted #999999;
    }
}
</style>
